import React, { useState } from "react";
import CommonDocuments from "../../../common/Documents";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { isEmpty } from "lodash";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { format, parse } from "date-fns";
import update from "immutability-helper";
import { get, sumBy, flatten, values, map, set } from "lodash";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 500,
  },
  table: {},
}));

const ConfirmShipout = observer(({ outbound }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [shipoutAt, setShipoutAt] = useState(new Date());
  const [items, setItems] = useState({});

  const { shipoutDocuments, loading, bols } = outbound;

  const handleOpen = () => {
    if (outbound.shipout_at) {
      const _items = {};

      bols.forEach((bol) => {
        bol.items.forEach((item) => {
          set(
            _items,
            [`${bol.id}`,`${item.ib_shipment_number}`],
            item.shipout_pallet_count
          );
        });
      });

      setShipoutAt(
        parse(outbound.shipout_at, "MM/dd/yyyy HH:mm:ss", new Date())
      );
      setItems(_items);
    }
    shipoutDocuments.setDefer(false);
    shipoutDocuments.load(outbound.id, "outbound_shipout");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    outbound
      .shipout({
        items,
        shipout_at: format(shipoutAt, "yyyy-MM-dd HH:mm:ss"),
      })
      .then((response) => {
        if (response) {
          setOpen(false);
          setItems({});
          outbound.outbounds.loadCollection();
        }
      });
  };

  const handleCancel = () => {
    outbound.unshipout().then((response) => {
      if (response) {
        setOpen(false);
        setItems({});
        outbound.outbounds.loadCollection();
      }
    });
  };

  const handleShipoutPalletCountChange = (value, bol, containerNumber) => {
    if (get(items, `${bol.id}.${containerNumber}`)) {
      setItems(
        update(items, { [bol.id]: { [containerNumber]: { $set: value } } })
      );
    } else {
      setItems(
        update(items, {
          [bol.id]: {
            $set: { ...get(items, `${bol.id}`, {}), [containerNumber]: value },
          },
        })
      );
    }
  };

  const handleFullyShipout = () => {
    const _items = {};

    bols.forEach((bol) => {
      bol.items.forEach((item) => {
        if (!_items[bol.id]) {
          _items[bol.id] = {};
        }

        _items[bol.id][item.ib_shipment_number] = item.remaining_pallet_count;
      });
    });

    setItems(_items);
  };

  const canSubmit = () => {
    return (
      !loading &&
      shipoutDocuments.hasDocuments &&
      !isEmpty(items) &&
      shipoutAt &&
      !isNaN(shipoutAt.getTime())
    );
  };

  const totalShipoutPalletCount = React.useMemo(
    () =>
      sumBy(flatten(map(values(items), (i) => values(i))), (v) =>
        parseFloat(v || 0)
      ).toFixed(2),
    [items]
  );

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleOpen}
      >
        {outbound.shipout_at ? "Update" : "Confirm"} Shipout
      </Button>
      {open && (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Box className={classes.content}>
                <Box className={classes.subtitle}>
                  1. Please input shipout date and time *
                </Box>
                <Box mt={1}>
                  <FormControl required>
                    <KeyboardDateTimePicker
                      variant="inline"
                      value={shipoutAt}
                      onChange={setShipoutAt}
                      format="yyyy-MM-dd HH:mm"
                    />
                  </FormControl>
                </Box>
                <Box mt={3} className={classes.subtitle}>
                  2. Please fill in the Shipout Pallet Count *{" "}
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleFullyShipout}
                  >
                    Fully Shipout
                  </Button>
                </Box>
                <Box mt={1}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>BOL#</TableCell>
                        <TableCell>IB Shipment#</TableCell>
                        <TableCell>Inbound Pallet Count</TableCell>
                        <TableCell>WHS Pallet Count</TableCell>
                        <TableCell>Shipout Pallet Count</TableCell>
                        <TableCell>Remaining Pallet Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bols.map((bol) => (
                        <React.Fragment key={bol.id}>
                          {bol.items.map((item) => (
                            <TableRow key={item.ib_shipment_number}>
                              <TableCell component="th" scope="row">
                                {bol.uid}
                              </TableCell>
                              <TableCell>{item.ib_shipment_number}</TableCell>
                              <TableCell>{item.inbound_pallet_count}</TableCell>
                              <TableCell>{item.whs_pallet_count}</TableCell>
                              <TableCell>
                                <OutlinedInput
                                  type="number"
                                  size="small"
                                  margin="dense"
                                  value={
                                    items[bol.id]
                                      ? items[bol.id][item.ib_shipment_number]
                                      : ""
                                  }
                                  inputProps={{
                                    max: outbound.shipout_at
                                      ? null
                                      : item.remaining_pallet_count,
                                  }}
                                  onChange={(e) =>
                                    handleShipoutPalletCountChange(
                                      e.target.value,
                                      bol,
                                      item.ib_shipment_number
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {item.remaining_pallet_count}
                              </TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>
                          {parseFloat(
                            outbound.total_inbound_pallet_count || 0
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {parseFloat(
                            outbound.total_whs_pallet_count || 0
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell>{totalShipoutPalletCount}</TableCell>
                        <TableCell>
                          {parseFloat(
                            outbound.total_remaining_pallet_count || 0
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
                <Box mt={3} className={classes.subtitle}>
                  3. Upload Pickup Receipt *
                </Box>
                <Box mt={1}>
                  <CommonDocuments
                    documents={shipoutDocuments}
                    onUploaded={shipoutDocuments.load}
                    onDeleted={() => { }}
                    pastable
                  />
                </Box>
                <Box mt={3} display="flex" justifyContent="flex-end">
                  {!!outbound.shipout_at && (
                    <Box mr={2}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        disabled={loading}
                      >
                        Cancel Shipout
                      </Button>
                    </Box>
                  )}
                  <Box>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={!canSubmit()}
                    >
                      {outbound.shipout_at ? "Update" : "Confirm"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

export default ConfirmShipout;
